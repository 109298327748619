import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import {firestorePlugin} from 'vuefire'
import router from './router'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

Vue.use(firestorePlugin)
Vue.use(Buefy)
Vue.use(VueMoment, {moment})

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
