<template>
    <div class="columns">
      <div class="column is-four-fifths">
        <div class="card is-gapless">
          <header class="card-header">
            <p class="card-header-title">{{ title }}</p>
          </header>
          <div class="card-content has-text-left">
            <b-table :data="observations"
                     :selected.sync="selected"
                     id="observations_table"
                     striped
                     hoverable
                     narrowed
                     paginated
                     per-page="15"
                     sort-icon="arrow-up"
            >
              <b-table-column field="time" label="Date (UTC)" sortable v-slot="props">
                {{ props.row.time.toDate().toISOString().substring(0, 19).replace('T', ' ') }}
              </b-table-column>
              <b-table-column field="unit" label="Unit" sortable v-slot="props">
                {{ props.row.id.split('_')[0] }}
              </b-table-column>
              <b-table-column field="camera" label="Camera" sortable v-slot="props">
                {{ props.row.id.split('_')[1] }}
              </b-table-column>
              <b-table-column field="id" label="ID" sortable v-slot="props">{{ props.row.id }}</b-table-column>
              <b-table-column field="field_name" label="Field name" sortable v-slot="props">
                {{ props.row.field_name }}
              </b-table-column>
              <!--            <b-table-column field="coords" label="RA/Dec (deg)" sortable v-slot="props">-->
              <!--            <span v-if="props.row.coordinates.mount_ra">-->
              <!--              {{ props.row.coordinates.mount_ra.toFixed(2) }}° {{ props.row.coordinates.mount_dec.toFixed(2) }}°-->
              <!--            </span>-->
              <!--            </b-table-column>-->
              <b-table-column field="num_images" label="Images" sortable v-slot="props" numeric>
                {{ props.row.num_images }}
              </b-table-column>
              <b-table-column field="total_minutes_exptime" label="Total minutes" sortable v-slot="props" numeric>
                {{ (props.row.total_exptime / 60).toFixed(2) }}
              </b-table-column>
              <b-table-column field="status" label="Status" sortable v-slot="props" :visible=false>
                <b-tag
                    v-if="props.row.status"
                    :type="{
                'is-primary': props.row.status == 'CREATED',
                'is-warning': props.row.status == 'PROCESSING',
                'is-success': props.row.status == 'PROCESSED',
                'is-danger': (props.row.status == 'TOO_MUCH_DRIFT' || props.row.status == 'NOT_ENOUGH_FRAMES'),

              }"
                >{{ props.row.status }}
                </b-tag>
              </b-table-column>
<!--              <template #footer>-->
<!--                <div class="has-text-right">-->
<!--                  <b-button label="Search" class="is-success" @click="modalActive = true"></b-button>-->
<!--                  <b-modal v-model="modalActive"-->
<!--                    has-modal-card-->
<!--                  >-->
<!--                    <template>-->
<!--                      <div class="card">-->
<!--                        <SearchObservations></SearchObservations>-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </b-modal>-->
<!--                </div>-->
<!--              </template>-->
            </b-table>
          </div>
        </div>
      </div>
      <div class="column">
        <ObservationDetail :observation="selected"/>
      </div>
    </div>
</template>

<script>
import {db} from "../db";
import ObservationDetail from "@/components/ObservationDetail";
// import SearchObservations from "@/components/SearchObservations";

export default {
  name: 'Observations',
  components: {
    ObservationDetail,
    // SearchObservations
  },
  data() {
    return {
      base_url: 'https://storage.googleapis.com/panoptes-images-processed',
      title: 'Recent Observations',
      observations: [],
      selected: [],
      modalActive: false
    }
  },
  firestore: {
    observations: db.collectionGroup('observations')
        .where('time', '>', new Date('2021-01-1'))
        .orderBy('time', 'desc')
        .limit(25)
  },
}
</script>
