<template>
  <div class="card">
<!--    <div class="card-image">-->
<!--      <figure class="image is-4by3">-->
<!--        <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image">-->
<!--      </figure>-->
<!--    </div>-->
    <div class="card-content">
      <p class="title is-4">{{ observation.field_name }}</p>
      <p class="subtitle is-6">{{ observation.sequence_id }}</p>
      <time>{{ observation.time.toDate().toLocaleString() }}</time>
      <p class="subtitle is-6">Exptime: {{ images[0].camera.exptime }} seconds</p>

      <div class="content">
        <b-table
            :data="images"
            :selected.sync="selected"
            id="images_table"
            striped
            hoverable
            narrowed
            paginated
            :per-page="perPage"
            pagination-simple
            pagination-size="is-small"
            default-sort="time"
            default-sort-direction="desc"
            sort-icon="arrow-up"
        >
          <b-table-column field="time" label="Time (UTC)" sortable v-slot="props">
            {{ props.row.time.toDate() | moment('timezone', 'UTC', 'HH:mm:ss') }}
          </b-table-column>
          <b-table-column field="coords" label="RA/Dec (deg)" sortable v-slot="props" :visible=false>
                        <span v-if="props.row.coordinates.ra">
                          {{ props.row.coordinates.ra.toFixed(2) }}° {{
                            props.row.coordinates.dec.toFixed(2)
                          }}°
                        </span>
          </b-table-column>
          <b-table-column field="sources.num_detected" label="Stars" sortable v-slot="props">
            {{ props.row.sources.num_detected }}
          </b-table-column>
          <b-table-column field="public_url" label="URL" v-slot="props">
            <b-button tag="a"
                      :href="base_url + '/' + props.row.uid.replaceAll('_', '/') + '-reduced.fits.fz'"
                      type="is-link is-light is-small">.FITS
            </b-button>
          </b-table-column>
        </b-table>
        <br>

        <b-dropdown aria-role="list">
            <template #trigger="{ active }">
                <b-button
                    label="Download FITS List"
                    type="is-outline"
                    :icon-right="active ? 'menu-up' : 'menu-down'" />
            </template>


            <b-dropdown-item aria-role="listitem">
              <a :href='function_url + observation.sequence_id'>Reduced</a>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem">
              <a :href='function_url + observation.sequence_id + "&raw=true"'>Raw</a>
            </b-dropdown-item>
        </b-dropdown>

      </div>
    </div>
  </div>
</template>

<script>
import {db} from "../db";

export default {
  name: "ObservationDetail",
  props: ['observation'],
  data() {
    return {
      base_url: 'https://storage.googleapis.com/panoptes-images-processed',
      function_url: 'https://us-central1-panoptes-exp.cloudfunctions.net/get-observation-file-list?sequence_id=',
      perPage: 10,
      images: [],
      selected: null,
    }
  },
  watch: {
    observation: {
      immediate: true,
      handler(observation) {
        this.images.splice(0);
        this.$bind('images', db.doc('units/' + observation.unit_id + '/observations/' + observation.sequence_id).collection('images'))
      }
    }
  }
}
</script>

<style scoped>

</style>
