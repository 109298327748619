<template>
  <section>
    <Banner/>
    <ObservationsTable/>
  </section>
</template>

<script>
// import Sidebar from "@/components/Sidebar";
import Banner from "@/components/Banner";
import ObservationsTable from "@/components/ObservationsTable";

export default {
  name: 'Home',
  components: {
    // Sidebar,
    Banner,
    ObservationsTable
  }
}
</script>
